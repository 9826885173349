













































import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class PathNotFound extends Vue {
  get isDark(): boolean {
    return this.$store.state.appConfig.layout.skin === 'dark'
  }
}
